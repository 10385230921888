import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      hideForAuth: true
    },
    name: 'login',
       component: () => import('../components/login.vue')
  },
  {
    path: '/mantenimientos',
    name: 'Mantenimientos de unidades comerciales',
    meta: {
      requiresAuth: true
    },
       component: () => import('../views/mantenimientos.vue')
  },
  {
    path: '/rentas',
    name: 'Renta de unidades comerciales',
    meta: {
      requiresAuth: true
    },
       component: () => import('../views/rentas.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if(localStorage.getItem('userProfileMantenimientosComerciales') === null){
          next({ path: '/' });
      } else {
          next();
      }

  } else {
      next();
  }
});

export default router