import Vue from 'vue'
import Vuex from 'vuex'
import mantenimientosBackend from "../backend/api"

Vue.use(Vuex)
let mantenimientosBackendApi = new mantenimientosBackend();

export default new Vuex.Store({

  state: {
    userAccount: undefined,
    userGraphInfo: undefined,
    appTitle: 'Saúl E. Méndez',
    menuItems: [
      { title: 'Mantenimientos', path: '/mantenimientos', icon: 'mdi-calendar', action: 'console' },
      { title: 'Rentas', path: '/rentas', icon: 'mdi-table-chair', action: 'console' },
      { title: 'Cerrar Sesión', path: '', icon: 'mdi-logout', action: 'SignOut' }
    ],
    dataMantenimientos: [],
    dataRentas: [],
    dataEstados: [],
    loading: false
  },

  getters: {
  },

  mutations: {
    setUserAccount: (state, payload) => {
      const { account } = payload
      state.userAccount = JSON.parse(account)
    },
    setUserGraphInfo: (state, payload) => {
      const { graphInfo } = payload
      state.userGraphInfo = graphInfo
    },
    mostrarLoading: (state, payload) => {
      if(payload.lanzar){
        Swal.fire({
            title: payload.texto,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
      }else{
        Swal.close()
      }
    },
    mostrarMsgTopPosition(state, payload) {
        Swal.fire({
          position: 'top-end',
          icon: payload.icon,
          title: payload.texto,
          showConfirmButton: false,
          timer: 1500
        })
    },
    autoLlenarMantenimientos(state, mantenimientos) {
      state.dataMantenimientos = mantenimientos;
    },
    autoLlenarRentas(state, rentas) {
      state.dataRentas = rentas;
    }
  },

  actions: {
    async fillUserFromLocalStorage({ commit }){
      if(localStorage.getItem('userProfileMantenimientosComerciales') !== null){
        commit("setUserAccount", {
          account: localStorage.getItem('userProfileMantenimientosComerciales'),
        });
      }
    },

    async getMantenimientos({ commit }) {
      commit("mostrarLoading", {lanzar: true, texto: 'Cargando'});
      let mantenimientosArray = [];
      const mantenimientos = await mantenimientosBackendApi.getMantenimientos();

      mantenimientos.forEach(element => {
        let mantenimiento = {
          id: element.id,
          CodigoUnidad: element.CodigoUnidad,
          Concepto: element.Concepto,
          CentroCosto: element.CentroCosto,
          ProveedorMantenimiento: element.ProveedorMantenimiento,
          Plazo: element.Plazo,
          FechaInicioContrato: element.FechaInicioContrato,
          FechaFinalContrato: element.FechaFinalContrato,
          Mantenimiento: element.Mantenimiento,
          UsuarioCreacion: element.UsuarioCreacion,
          FechaCreacion: element.FechaCreacion,
          MetrosCuadrados: element.MetrosCuadrados,
          PorcentajeIncrementoAnual: element.PorcentajeIncrementoAnual,
          FechaIncremento: element.FechaIncremento,
          Estado: element.Estado
        };
        mantenimientosArray.push(mantenimiento);
      });
      commit('autoLlenarMantenimientos', JSON.parse(JSON.stringify(mantenimientosArray)));
      commit("mostrarLoading", {lanzar: false, texto: ''});
    },

    async getEstados({ state }) {
      let estadosArray = [];
      const estados = await mantenimientosBackendApi.getEstados();

      estados.forEach(element => {
        let mantenimiento = {
          value: element.id,
          text: element.Nombre,
          color: element.Color
        };
        estadosArray.push(mantenimiento);
      });
      state.dataEstados = JSON.parse(JSON.stringify(estadosArray));
    },

    async getRentas({ commit }) {
      commit("mostrarLoading", {lanzar: true, texto: 'Cargando'});
      let rentasArray = [];
      const rentas = await mantenimientosBackendApi.getRentas();

      rentas.forEach(element => {
        let renta = {
          id: element.id,
          CodigoUnidad: element.CodigoUnidad,
          Concepto: element.Concepto,
          CentroCosto: element.CentroCosto,
          ProveedorRenta: element.ProveedorRenta,
          Plazo: element.Plazo,
          FechaInicioContrato: element.FechaInicioContrato,
          FechaFinalContrato: element.FechaFinalContrato,
          RentaBase: element.RentaBase,
          UsuarioCreacion: element.UsuarioCreacion,
          MetrosCuadrados: element.MetrosCuadrados,
          PorcentajeIncrementoAnual: element.PorcentajeIncrementoAnual,
          FechaIncremento: element.FechaIncremento,
          Estado: element.Estado,
          PorcentajeRentaVariable: element.PorcentajeRentaVariable
        };
        rentasArray.push(renta);
      });
      commit('autoLlenarRentas', JSON.parse(JSON.stringify(rentasArray)));
      commit("mostrarLoading", {lanzar: false, texto: ''});
    },
  },

  modules: {
  }
})
