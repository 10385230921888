import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import swal from 'sweetalert2';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import moment from 'moment';
import mantenimientosBackend from "./backend/api"
import currency from './helpers/currency'
import date from './helpers/date'
import exporters from './helpers/exporters'

Vue.config.productionTip = false;
Vue.prototype.$msalInstance = {};
Vue.prototype.$mantenimientosApi = new mantenimientosBackend();
Vue.prototype.$currencyHelpers = currency;
Vue.prototype.$dateHelpers = date;
Vue.prototype.$exportersHelpers = exporters;

window.Swal = swal;
window.jsPDF = jsPDF;
window.XLSX = XLSX;
window.moment = moment;

var vm =  new Vue({
  router,
  store,
  vuetify,
  jsPDF,
  autoTable,
  XLSX,
  moment,
  swal,
  render: h => h(App)
}).$mount('#app')

global.vm = vm;