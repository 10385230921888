<template>
  <v-app id="App">
    
      <barNav />
   
  </v-app>
</template>

<script>
import  barNav from  "../src/components/barNav.vue";

export default {
  name: "App",
   components: {
    barNav
  },
  data: () => ({
    //
  }),
};
</script>
