export default {

    exportPDF(columns, data, name) {
        try {
            let doc = new jsPDF('landscape', 'mm', 'a4');
            doc.text("Reporte de Mantenimiento de Unidades Comerciales", 70, 7);
            doc.autoTable(columns, data, { startY: 10 });
            doc.save(`${name}.pdf`);
        } catch (e) {
            this.$store.commit("mostrarMsgTopPosition", { icon: 'error', texto: 'Error al generar el archivo, intentelo de nuevo' })
        }
    },

    exportExcel(data, name){
        try {
          let jsonData = XLSX.utils.json_to_sheet(data)
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, jsonData, 'jsonData')
          XLSX.writeFile(wb,`${name}.xlsx`)
        } catch (e) {
          this.$store.commit("mostrarMsgTopPosition", {icon: 'error', texto: 'Error al generar el archivo, intentelo de nuevo'})
        }
      }

}