export default class mantenimientosBackend {
    constructor() {
        const hostname = window.location.hostname;
        const regExpName = 'https://func-mantos-service';
        const currentEnvironment = hostname.includes('dev') ? `${regExpName}-dev.azurewebsites.net` : hostname.includes('local') ? 'http://localhost:7071' : `${regExpName}-prod.azurewebsites.net`;
        this.apiBase = `${currentEnvironment}/api`;
        console.log(this.apiBase)
    }

    async getMantenimientos() {
        let response = await this.GET("/mantenimientos_get_http");
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async updateMantenimientos(mantenimiento){
        let response = await this.PUT("/mantenimientos_update_put_http", mantenimiento);
        return response;
    }

    async createMantenimientos(mantenimiento){
        let response = await this.POST("/mantenimientos_create_post_http", mantenimiento);
        return response;
    }

    async deleteMantenimientos(id, usuario){
        let response = await this.DELETE(`/mentenimientos_by_id_delete_http?id=${id}&usuario=${usuario}`);
        return response;
    }

    async getEstados() {
        let response = await this.GET("/estados_get_http");
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async getRentas() {
        let response = await this.GET("/rentas_get_http");
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async updateRentas(renta){
        let response = await this.PUT("/rentas_update_put_http", renta);
        return response;
    }

    async createRentas(renta){
        let response = await this.POST("/rentas_create_post_http", renta);
        return response;
    }

    async deleteRentas(id, usuario){
        let response = await this.DELETE(`/rentas_by_id_delete_http?id=${id}&usuario=${usuario}`);
        return response;
    }

    /* METHODS */

    async POST(endPoint, body) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }

    async PUT(endPoint, body) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)

        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }

    async GET(endPoint) {
        let response = await fetch(`${this.apiBase}${endPoint}`);

        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return response;
    }

    async DELETE(endPoint) {
        const requestOptions = {
            method: "DELETE"
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);

        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }
};