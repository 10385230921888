import { PublicClientApplication } from "@azure/msal-browser";

const TOKEN_GRAPH_SCOPES = ["user.read", "user.readbasic.all"];
const LOGIN_SCOPES = ["user.read", "openid", "profile", "email"];

let msalApp;

export default {
  async configure() {
    if (msalApp) {
      return;
    }
    const config = {
      auth: {
        clientId: process.env.VUE_APP_AAD_APP_CLIENT_ID,
        redirectUri: window.location.origin,
        authority:
        `https://login.microsoftonline.com/${process.env.VUE_APP_AAD_TENANT_ID}`,
      },
      cache: {
        cacheLocation: "localStorage",
      },
    };

    msalApp = new PublicClientApplication(config);
  },
  async SignIn() {
    await msalApp
      .loginPopup({
        LOGIN_SCOPES,
        prompt: "select_account",
      })
      .then(() => {
        this.user();
        return this.user();
      })
      .catch((error) => {
        console.error(`Error en la autenticación: ${error}`);
      });
  },
  async SignOut() {
    // Cerrar sesión local (solo a nivel de aplicación)
    this.clearLocalSession();

    // Cerrar sesión remota (todo lo que tenga que ver con Office 365)
    await msalApp
        .logout({})
        .then(() => {
          return true
        })
        .catch(error => {
          console.error(error);
          return false
        });
  },
  user() {
    if (!msalApp) {
      return null;
    }

    const currentAccounts = msalApp.getAllAccounts();
    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
      return null;
    } else if (currentAccounts.length > 1) {
      this.createLocalSession(JSON.stringify(currentAccounts[0]));
      return currentAccounts[0];
    } else {
      this.createLocalSession(JSON.stringify(currentAccounts[0]));
      return currentAccounts[0];
    }
  },
  async getAccessToken() {
    const accessTokenRequest = {
      TOKEN_GRAPH_SCOPES,
      account: this.user(),
    };

    let tokenResponse;
    try {
      tokenResponse = await msalApp.acquireTokenSilent(accessTokenRequest);
    } catch (err) {
      tokenResponse = await msalApp.acquireTokenPopup(accessTokenRequest);
    }

    if (!tokenResponse.accessToken) {
      throw new Error("### accessToken not found in response");
    }
    return tokenResponse.accessToken;
  },
  clearLocalSession() {
    if (msalApp) {
      for (let entry of Object.entries(localStorage)) {
        let key = entry[0];
        if (key.includes("login.windows")) {
          localStorage.removeItem(key);
        }
      }
    }
    localStorage.removeItem('userProfileMantenimientosComerciales');
  },
  createLocalSession(userProfile){
    if(userProfile){
      localStorage.removeItem('userProfileMantenimientosComerciales');
      localStorage.setItem('userProfileMantenimientosComerciales', userProfile);
    }
  }
};