<template>
  <v-app id="inspire">
    <div>
      <v-toolbar color="black">
        <span class="hidden-sm-and-up">
          <v-app-bar-nav-icon color="white" @click="sidebar = !sidebar"> </v-app-bar-nav-icon>
        </span>
        <v-toolbar-title>
          <router-link to="/" tag="span" style="cursor: pointer">
            <v-img :src="require('../assets/logoSaul.png')" class="my-10" contain height="50" />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-xs-only" v-if="userAccount">
          <v-btn class="white--text" color="black" v-for="item in menuItems" :key="item.title" :to="item.path"
            @click="invokeMethod(item.action)">
            <v-icon left dark>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-navigation-drawer v-model="sidebar" absolute temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
            <v-list-item v-for="item in menuItems" :key="item.title" :to="item.path" @click="invokeMethod(item.action)">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                {{ item.title }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import auth from "../services/auth";

export default {
  components: {},

  data() {
    return {
      group: false,
      sidebar: false,
    };
  },

  mounted() {
    this.getUserFromLocalSession()
  },

  computed: {
    ...mapState(["menuItems", "userAccount"]),
  },
  methods: {
    invokeMethod(methodName) {
      this[methodName]();
    },
    console() {
      console.log(' ')
    },
    async SignOut() {
      try {
        let logout = await auth.SignOut();
        if (logout) {
          this.$store.commit("setUserAccount", {
            account: undefined,
            userGraphInfo: undefined
          });
        }
      } catch (err) {
        this.error = err.toString();
      }
    },

    getUserFromLocalSession() {
      if (!this.userAccount) {
        this.$store.commit("setUserAccount", {
              account: localStorage.getItem('userProfileMantenimientosComerciales'),
        });
      }
    }
  },
};
</script>